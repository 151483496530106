import { useOrderContext } from '../../../../OrderContext';
import { getEnv } from '@ncs-frontend-monorepo/utils';
import { useState } from 'react';
import dynamic from 'next/dynamic';

const Checkbox = dynamic(
  () => import('dss-ui-library').then(({ Checkbox }) => Checkbox),
  {
    ssr: false,
  },
);

export const CustomerCheckbox: React.FC = () => {
  const { formData, saveFormData } = useOrderContext();
  const [isChecked, setIsChecked] = useState(formData?.isCustomer ?? false);
  const handleClick = () => {
    saveFormData({
      isCustomer: !isChecked,
    });
    setIsChecked(!isChecked);
  };

  return (
    <>
      <Checkbox
        label={`Ich habe bereits einen Internet-Anschluss von ${
          getEnv().SITE
        }.`}
        name="isCustomer"
        e2e="is-customer"
        checked={isChecked}
        onChange={handleClick}
      />
    </>
  );
};
